/* THE FOUR STEPS TO BEING AN AWESOME UI ENGINEER (AND CO-CODER)
1. Try to keep custom styles to a minimum.
2. Be aware that these styles will affect your entire application.
3. Namespace your own custom styles with something
related to your app, for example, .spark-classname or .apollo-classname.
4. Use Canvas global variables for color, spacing and more.
They are CSS variables that are already globally available in the browser.
The full list is located here: https://canvas.prod.target.com/design-tokens. */

$mainHeaderHeight: 56px;

.praxcss-avatarButton {
  border: 0;
  padding: 0;
  border-radius: var(--canvas-border-radius--expanded);

}.saved-search-menu-name {
   display: flex;
   flex-grow: 1;
 }

.saved-search-actions {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.save-search-text{
  min-height: 70px;
  min-width: 400px;
  margin: 0 16px;
}

.saved-search-menu-item {
  display: flex;
}

.white-header {
  color: white;
}

.header-icon-button {
  color: white;
  background-color: rgba(0,0,0,0);
  border: 0;
  padding: 0;
}

.header-icon-button:hover {
  background-color: rgba(0,0,0,0.1);
}

.blue-chip {
  background: var(--theme-colors-interactive, #3E72DB);
  color: var(--canvas-global-color--white);
  border: 0;
}

.C-TableAdvancedContainer {
  max-height: 54vh
}

@media (prefers-color-scheme: light) {
  .table-action-button {
    color: black;
    background-color: rgba(0,0,0,0);
    border: 0;
    padding: 0;
  }
  .table-action-button:hover {
    background-color: rgba(0,0,0,0.1);
  }
  .table-action-button:disabled {
    background-color: rgba(0,0,0,0);
  }
}

@media (prefers-color-scheme: dark) {
  .table-action-button {
    color: white;
    background-color: rgba(0,0,0,0);
    border: 0;
    padding: 0;
  }
  .table-action-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .table-action-button:disabled {
    background-color: rgba(0,0,0,0);
  }
}
